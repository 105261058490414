import { LifeCycles, navigateToUrl } from 'single-spa';
import * as iframeResizer from 'iframe-resizer/js/iframeResizer.js';
import { SubsiteOptions } from '../../../../common/types/global';
import { config, envConfig, pathPatterns } from '../config';
import { clearHashFragment, setHashFragment } from '../utils/hash';

const productValue = 'b2b-web';
const isDev = process.env.NODE_ENV === 'development';
const app = document.getElementById('app-iframe');
let onMessageFromIframe: (msg: MessageEvent) => void;
let checkLocation: number;
let $iframe: HTMLIFrameElement;
let receivedProps: SubsiteOptions;

const hideLoading = (): void => {
    if (receivedProps.showLoading) {
        // legacy apps take some time after load event to actually show initial content.
        // To avoid an empty white screen we keep on showing the loading spinner for a while
        setTimeout(() => {
            receivedProps.showLoading(false);
        }, 1500);
    }
};

const extractLastPathFromSource = (src: string): string => {
    let lastSegment = '';
    if (src) {
        lastSegment = src.split('/').pop()?.split(/#|\?/)[0] || '';
    }
    return lastSegment;
};

const getIframeSrc = (props: SubsiteOptions): string => {
    // Passing down the search and hash params for apps which might need it
    const params = `${window.location.search}${window.location.hash}`;
    switch (true) {
        case pathPatterns.currentCourse.test(window.location.pathname):
            return `${envConfig.iframeSrc.currentCourse}${params}`;
        case pathPatterns.grammarLab.test(window.location.pathname):
            return `${envConfig.iframeSrc.grammarLab}${params}`;
        case pathPatterns.flashcards.test(window.location.pathname):
            return `${envConfig.iframeSrc.flashcards}${params}`;
        case pathPatterns.translator.test(window.location.pathname):
            return `${envConfig.iframeSrc.translator}${params}`;
        case pathPatterns.appsAndTools.test(window.location.pathname):
            return `${envConfig.iframeSrc.appsAndTools}${params}`;
        case pathPatterns.currentBookings.test(window.location.pathname): {
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set('product', productValue);
            if (isDev) {
                searchParams.set('forceB2BAuth', 'true');
            }
            let currentBookingsUrl = isDev
                ? `${envConfig.iframeSrc.currentBookings}?${searchParams.toString()}`
                : new URL(`${envConfig.iframeSrc.currentBookings}?${searchParams.toString()}`, window.location.toString()).toString();

            if (props.enableNewClassesPath && (props.student?.grantsAndCredits.catalyst || props.student?.grantsAndCredits.hultEfPro)) {
                currentBookingsUrl = isDev
                    ? `${envConfig.iframeSrc.newCurrentBookings}?${searchParams.toString()}`
                    : new URL(
                        `${envConfig.iframeSrc.newCurrentBookings}?${searchParams.toString()}`,
                        window.location.toString(),
                    ).toString();
            }
            return currentBookingsUrl;
        }
        case pathPatterns.privateClass.test(window.location.pathname): {
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set('product', productValue);
            if (isDev) {
                searchParams.set('forceB2BAuth', 'true');
            }
            let privateClassUrl = isDev
                ? `${envConfig.iframeSrc.privateClass}?${searchParams.toString()}`
                : new URL(`${envConfig.iframeSrc.privateClass}?${searchParams.toString()}`, window.location.toString()).toString();
            if (props.enableNewClassesPath && (props.student?.grantsAndCredits.catalyst || props.student?.grantsAndCredits.hultEfPro)) {
                privateClassUrl = isDev
                    ? `${envConfig.iframeSrc.newPrivateClass}?${searchParams.toString()}`
                    : new URL(`${envConfig.iframeSrc.newPrivateClass}?${searchParams.toString()}`, window.location.toString()).toString();
            }
            return privateClassUrl;
        }
        case pathPatterns.groupClass.test(window.location.pathname): {
            const searchParams = new URLSearchParams(window.location.search);
            const hashParams = window.location.hash;
            searchParams.set('product', productValue);
            if (isDev) {
                searchParams.set('forceB2BAuth', 'true');
            }
            let groupClassUrl = isDev
                ? `${envConfig.iframeSrc.groupClass}?${searchParams.toString()}${hashParams}`
                : new URL(
                    `${envConfig.iframeSrc.groupClass}?${searchParams.toString()}${hashParams}`,
                    window.location.toString(),
                ).toString();
            if (props.enableNewClassesPath && (props.student?.grantsAndCredits.catalyst || props.student?.grantsAndCredits.hultEfPro)) {
                groupClassUrl = isDev
                    ? `${envConfig.iframeSrc.newGroupClass}?${searchParams.toString()}${hashParams}`
                    : new URL(
                        `${envConfig.iframeSrc.newGroupClass}?${searchParams.toString()}${hashParams}`,
                        window.location.toString(),
                    ).toString();
            }
            return groupClassUrl;
        }
        case pathPatterns.levelTest.test(window.location.pathname):
            return `${envConfig.iframeSrc.levelTest}${params}`;
        default:
            navigateToUrl(config.paths.notFound.url);
            return '';
    }
};

const getIframe = (src: string): HTMLIFrameElement => {
    const iframe = document.createElement('iframe');
    iframe.setAttribute('id', 'subsite-iframe');
    iframe.setAttribute('scrolling', 'no');
    iframe.setAttribute('data-qa', `iframe-${extractLastPathFromSource(src)}`);
    iframe.classList.add('c-iframe-app');
    iframe.src = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'ci'
        ? `?legacyIframeSrc=${src}`
        : src;

    return iframe;
};

const getHeightCalculationMethod = (): string => {
    // available heightCalculations: 'bodyOffset' | 'bodyScroll' | 'documentElementOffset' | 'documentElementScroll' |'max' | 'min' | 'grow' | 'lowestElement' | 'taggedElement'
    switch (true) {
        case pathPatterns.levelTest.test(window.location.pathname):
            return 'bodyScroll';
        case pathPatterns.currentCourse.test(window.location.pathname):
        case pathPatterns.privateClass.test(window.location.pathname):
        case pathPatterns.currentBookings.test(window.location.pathname):
        case pathPatterns.groupClass.test(window.location.pathname):
            return 'taggedElement';
        default:
            return 'lowestElement';
    }
};

function mount(props: SubsiteOptions): Promise<unknown> {
    receivedProps = props;
    if (app) {
        const src = getIframeSrc(props);
        $iframe = getIframe(src);
        app.appendChild($iframe);

        props.showLoading(true);
        $iframe.addEventListener('load', hideLoading);

        const heightCalculationMethod = getHeightCalculationMethod();

        iframeResizer({
            log: false,
            inPageLinks: true,
            heightCalculationMethod,
        }, '#subsite-iframe');

        const catalystGrant = props.student?.grantsAndCredits.catalyst;
        if (catalystGrant) {
            app.classList.add('c-gap-iframe');
        }

        props.onLanguageChange({
            callback: () => {
                if ($iframe.contentWindow) {
                    // Changing the language while having an open activity in studyplan doesn't work
                    // We can capture that with this activity specific hash and remove it
                    if ($iframe.contentWindow.location.hash.startsWith('#school/')) {
                        $iframe.contentWindow.location.hash = '';
                    }

                    $iframe.contentWindow.location.reload();
                }
            },
            translationsRequest: () => ({ keys: [] }),
        });

        onMessageFromIframe = (msg): void => {
            // This messeage needs to be manually triggered from the legacy apps
            // It should be used when the legacy apps need to redirect to an external site, e.g. EVC tech check
            if (msg.data.type === 'el:b2b:redirectParent') {
                if (msg.data.link) {
                    window.location.href = msg.data.link;
                }
            }

            // This messeage will be fired on the beforeunload event in every legacy App that includes the iframe.js.
            if (msg.data.type === 'el:b2b:unloadingIframe') {
                try {
                    const locationCheckInterval = 100;
                    let locationCheckAttempts = 200; // = 20 seconds (100ms * 200)
                    // eslint-disable-next-line
                    // @ts-ignore
                    const iframeLocationPath = $iframe.contentWindow.location.pathname;

                    checkLocation = window.setInterval(() => {
                        // In case the iFrame reloads the same URL we clear the interval after some time
                        locationCheckAttempts -= 1;
                        if (locationCheckAttempts <= 0) {
                            clearInterval(checkLocation);
                            return;
                        }

                        try {
                            // eslint-disable-next-line
                            // @ts-ignore
                            const updatedLocObj = $iframe.contentWindow.location;
                            const updatedLocationPath = updatedLocObj.pathname;
                            const updatedLocationFullRelative = `${updatedLocObj.pathname}${updatedLocObj.search}${updatedLocObj.hash}`;
                            if (updatedLocationPath !== iframeLocationPath) {
                                navigateToUrl(updatedLocationFullRelative);
                                clearInterval(checkLocation);
                            }
                        } catch (error) {
                            // Note on contentWindow
                            // Error may be thrown because we have no access to iframe.contentWindow on another origin
                            // Two possibilities we end up here:
                            // 1) On purpose: User was redirected to login page, so we simply reload and let the App Shell handle that
                            // 2) Accidently: All the legacy Apps inside iFrames must explicitly send a message of type el:b2b:redirectParent if they want to redirect to another origin,
                            // otherwise we end up here. Unfortunately we can't differentialte between case 1 and 2.
                            window.location.reload();
                        }
                    }, locationCheckInterval);
                } catch (error) {
                    // See "Note on contentWindow"
                    window.location.reload();
                }
            }

            if (msg.data.type === 'el:b2b:routeChange') {
                window.scrollTo({
                    top: 0,
                    // 'instant' value was removed from DOM types,
                    // but is still supported by all browsers, see:
                    // https://github.com/microsoft/TypeScript-DOM-lib-generator/issues/1195
                    // eslint-disable-next-line
                    // @ts-ignore
                    behavior: 'instant',
                });
            }

            if (msg.data.type === 'el:b2b:removeHashParams') {
                clearHashFragment(msg.data.keys);
            }

            if (msg.data.type === 'el:b2b:setHashParams') {
                setHashFragment(msg.data.params);
            }

            if (msg.data.type === 'el:b2b:modalOpen') {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }
        };

        window.addEventListener('message', onMessageFromIframe, false);
    }
    return Promise.resolve();
}

function unmount(): Promise<unknown> {
    if (app) {
        window.removeEventListener('message', onMessageFromIframe, false);
        app.innerHTML = '';

        $iframe.removeEventListener('load', hideLoading);

        if (receivedProps.showLoading) {
            receivedProps.showLoading(false);
        }

        if (checkLocation) {
            window.clearInterval(checkLocation);
        }
    }
    return Promise.resolve();
}

export const iframeApp: LifeCycles<SubsiteOptions> = {
    mount,
    unmount,
    bootstrap: () => Promise.resolve(),
};
